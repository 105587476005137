<template>
  <div class="custom-container">
    <b-row class="justify-content-between p-1 align-items-center">
      <h2 class="font-weight-bold text-dark">
        {{ $t('quotes') }}
      </h2>
      <div v-if="!loading">
        <b-button
          v-if="currentUser.role_name === 'admin'"
          variant="outline-primary"
          class="mr-1"
          @click="closeBid"
        >{{ $t('closeBid') }}</b-button>
        <b-button
          variant="info"
          class="mr-1"
          @click="
            $router.push({
              name: 'clientQuoteMetadata',
              params: { id: quote.id },
            })
          "
        >
          <feather-icon
            icon="EyeIcon"
            class="mr-1"
          />
          {{ $t('breakdown') }}
        </b-button>

        <b-button
          variant="primary"
          class="mr-1"
          @click="print"
        ><feather-icon
          size="16"
          class="mr-1"
          icon="PrinterIcon"
        />{{
          $t('print')
        }}</b-button>

        <b-button
          v-if="timer"
          variant="outline-success"
        ><feather-icon
          size="16"
          class="mr-1"
          icon="ClockIcon"
        />{{
          formattedTimer
        }}</b-button>
        <b-button
          v-else-if="!timer"
          variant="outline-danger"
        ><feather-icon
          size="16"
          class="mr-1"
          icon="ClockIcon"
        />{{
          $t('expired')
        }}</b-button>
      </div>
    </b-row>
    <div>
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {},

  data() {
    return {
      timer: null,
      loading: false,
    }
  },

  computed: {
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('quotes', ['quote']),

    formattedTimer() {
      return moment.utc(this.timer).format('HH:mm:ss')
    },
  },

  watch: {
    timer: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timer = moment
              .duration(this.timer)
              .subtract(1, 'seconds')
              .asMilliseconds()
          }, 1000)
        } else if (value < 0) {
          this.timer = null
        }
      },
      immediate: true,
    },
  },

  created() {
    this.loading = true
    if (this.$route.name === 'firstRound') {
      this.fetchSupplierBranchQuoteItems({
        quoteId: this.$route.params.quoteId,
        branchId: this.$route.query.branchId,
      })
      if (this.currentUser.role_name === 'auctioneer') {
        this.fetchQuoteBidding(this.$route.params.quoteId)
          .then(response => {
            if (response.quote_status !== 'bidding') {
              this.$router.push({
                name: 'quotes',
                params: { projectId: response.project_id },
              })
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('bidExpired'),
                  icon: 'ClockIcon',
                  variant: 'warning',
                },
              })
            }
            const remainingTime = moment(response.auction_expires_at).diff(
              moment(),
            )
            this.timer = remainingTime
          })
          .finally(() => {
            this.loading = false
          })
      }
      if (this.currentUser.role_name === 'admin') {
        this.fetchQuote(this.$route.params.quoteId)
          .then(response => {
            if (response.quote_status !== 'bidding') {
              this.$router.push({
                name: 'quotes',
                params: { projectId: response.project_id },
              })
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('bidExpired'),
                  icon: 'ClockIcon',
                  variant: 'warning',
                },
              })
            }
            const remainingTime = moment(response.auction_expires_at).diff(
              moment(),
            )
            this.timer = remainingTime
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  },

  methods: {
    ...mapActions('quotes', [
      'fetchQuote',
      'fetchQuoteBidding',
      'fetchSupplierBranchQuoteItems',
      'closeQuoteBidding',
    ]),

    closeBid() {
      this.closeQuoteBidding(this.$route.params.quoteId).then(response => {
        this.$router.push({
          name: 'quotes',
          params: { projectId: response.project_id },
        })
      })
    },

    print() {
      window.print()
    },
  },
}
</script>

<style></style>
